//WILL MATCH 
//http://localhost:8000/vqs/anything/anything
import React, { useState, useEffect } from 'react';
import { Link, navigate } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"

import "./style.css";
let json_current = require("assets/renal.json");


function checkAnswer(option, correctAnswer) {
  if (option === correctAnswer) {
    console.log("Correct");
    return true;
  } else {
    console.log("Incorrect");
    return false;
  }
}

function Foo (props) {
const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

return(
  
  <div className="columns is-centered">
    <div className="column is-8-desktop is-10-tablet is-12-mobile">
      <div className="columns is-centered">
        <div className="column is-12-mobile is-12-tablet is-12-desktop">
          <div className="relevancy">
            <div className="suggested-mcq-exam-purpose">
              <span className="icon is-medium">
                <i className="fas fa-broadcast-tower" />
              </span>
              <p>{props.relevancy}</p>
            </div>
            <div>
              <div className="suggested-mcq-exam-purpose">
                <span className="icon is-medium is-black-text">
                  <i className="fas fa-tags" />
                </span>
                <p>{props.tags}</p>
              </div>
            </div>
          </div>
          <h2 className="decorated has-text-weight-bold global-text">
            <span className="is-size-7">Muneeb Akhter</span>
          </h2>
          <p className="gr-question">{props.question}</p>

          {props.options.map((option) => (
            <div className="set-question--choice">
              <div className="lights-out-on">
                <i className="fas fa-eye-slash is-size-5 global-text" />
              </div>
              <div className="set-q-text global-text">
                <a
                  onClick={() => checkAnswer(option, props.correctAnswer)}
                  className="answer"
                >
                  {option}
                </a>
              </div>
              <div className="set-q-id" />
            </div>
          ))}

        </div>
      </div>
      <p className="correct-choice is-hidden">Decreased HCO3-</p>
      <div id="pagination-container">
        <svg
          viewBox="0 0 100 8"
          preserveAspectRatio="none"
          style={{ width: "100%", height: "100%" }}
        >
          <path
            d="M 0,4 L 100,4"
            stroke="#eee"
            strokeWidth={8}
            fillOpacity={0}
          />
          <path
            d="M 0,4 L 100,4"
            stroke="rgb(89,155,255)"
            strokeWidth={8}
            fillOpacity={0}
            style={{ strokeDasharray: "100, 100", strokeDashoffset: 100 }}
          />
        </svg>
      </div>
      <div className="is-hidden-tablet">
        <div role="navigation" aria-label="Pagination" className="pagination">
          <span className="previous_page disabled">← Previous</span>{" "}
          <a
            className="next_page"
            rel="next"
            href="/smart-sets/acid-base-disorders?page=2"
          >
            Next →
          </a>
        </div>
      </div>
      <div className="is-hidden-mobile">
        <div
          role="navigation"
          aria-label="Pagination"
          className="pagination"
          separator=" "
        >
          <span className="previous_page disabled" onClick={props.previousMCQ}>← Previous</span>{" "}
          <em className="current" aria-label="Page 1" aria-current="page">
            1
          </em>{" "}
          <a
            rel="next"
            aria-label="Page 2"
            href="/smart-sets/acid-base-disorders?page=2"
          >
            2
          </a>{" "}
          <a className="current" aria-label="Page 3" href="/smart-sets/acid-base-disorders?page=3">
            3
          </a>{" "}
          <span className="gap">…</span>{" "}
          <a
            aria-label="Page 26"
            href="/smart-sets/acid-base-disorders?page=26"
          >
            26
          </a>{" "}
          <a
            aria-label="Page 27"
            href="/smart-sets/acid-base-disorders?page=27"
          >
            27
          </a>{" "}
          <a
            className="next_page"
            rel="next"
            onClick={props.nextMCQ}
          >
            Next →
          </a>
        </div>
      </div>
      <div className="columns is-centered padding-xs">
        <div className="column is-12-mobile is-10-tablet is-8-desktop">
          <center>
            <span className="global-text">
              Muneeb Akhter, you're on question {props.currentQuestion} of{" "}
              <b>{props.totalQuestions}</b>
            </span>
          </center>
        </div>
      </div>
      <div className="columns is-multiline is-centered has-text-centered">
        <div className="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen mcq_tagged_toolbar">
          <p
            id="lab-value-box-toggle"
            className="button is-link lab-value-link has-text-weight-bold"
          >
            <i className="fas fa-vial" /> &nbsp;{" "}
            <span className="is-hidden-mobile">Instant</span>&nbsp; Lab Values{" "}
          </p>
          <a
            href="#"
            id="report_question_button"
            className="button has-text-weight-semibold global-text"
          >
            <i className="far fa-question-circle" /> &nbsp; Report Question
          </a>
          <a
            href="#"
            id="save_question_button"
            className="button has-text-weight-semibold is-link"
          >
            <i className="fas fa-bookmark" /> &nbsp; Save Question
          </a>
          <div id="myModal2" className="modal2">
            <div className="modal-content2">
              <div className="column is-8">
                <form
                  className="new_saved_item"
                  id="new_saved_item"
                  action="/mcqs/a-45-year-old-patient-is-brought-to-the-emergency-room-in-a-delirious-state-with-weakness-blurred-vision-dehydration-and-hypothermia-he-was-diagnosed-with-type-1-diabetes-mellitus-30-years-back-on-examination-he-has-a-rapid-pulse-and-her-breathing-is-deep-and-labored-with-a-sickly-sweet-odor-his-blood-glucose-is-256-mg-dl-and-his-urinary-dipstick-shows-2-ketonuria-what-is-the-most-important-derangement-to-be-seen-on-the-arterial-blood-gases-abg-of-this-patient/saved_items"
                  acceptCharset="UTF-8"
                  data-remote="true"
                  method="post"
                >
                  <input name="utf8" type="hidden" defaultValue="✓" />
                  <textarea
                    className="textarea is-normal global-input"
                    placeholder="Add your notes here before saving this question"
                    name="saved_item[saved_item_notes]"
                    id="saved_item_saved_item_notes"
                    defaultValue={""}
                  />
                  <br />
                  <input
                    type="submit"
                    name="commit"
                    defaultValue="Save Question"
                    data-disable-with="Saving.. "
                    className="button is-info has-text-weight-semibold"
                  />
                </form>
              </div>
              <h5 id="close-review-box2">Close</h5>
            </div>
          </div>
          <br />
        </div>
      </div>
      <p />
    </div>
  </div>
);
          }
Foo.defaultProps = {
  question:
    "A 45-year-old patient is brought to the emergency room in adelirious state with weakness, blurred vision, dehydration, and hypothermia. He was diagnosed with type 1 diabetes mellitus 30 years back. On examination, he has a rapid pulse and her breathing is deep and labored, with a sickly sweet odor. His blood glucose is 256mg/dL and his urinary dipstick shows +2 ketonuria. What is the most important derangement to be seen on the arterial blood gases (ABG) of this patient?",
  correctAnswer: "Decreased HCO3-",
  options: [
    "Increased Na+",
    "Decreased HCO3-",
    "Decreased pCO2",
    "Decreased K+",
    "Increased serum pH"
  ],
  tags: "ELECTROLYTES AND ACID-BASE BALANCE MEDICINE-2",
  relevancy: "SUGGESTED QUESTION",
  totalQuestions: 27,
  currentQuestion: 2
};

function App() {
  return (
    <div>
      <h1>Hello CodeSandbox</h1>
      <h2>Start editing to see some magic happen!</h2>
      
      <Foo
        correctAnswer="Decreased HCO3-"
        tags="ELECTROLYTES AND ACID-BASE BALANCE MEDICINE-2"
        relevancy="SUGGESTED QUESTION"
        currentQuestion="2"
        totalQuestions="22"
        options={[
          "Increased Na+",
          "Decreased HCO3-",
          "Decreased pCO2",
          "Decreased K+",
          "Increased serum pH"
        ]}
        question="45-year-old patient is brought to the emergency room in adelirious state with weakness, blurred vision, dehydration, and hypothermia. He was diagnosed with type 1 diabetes mellitus 30 years back. On examination, he has a rapid pulse and her breathing is deep and labored, with a sickly sweet odor. His blood glucose is 256mg/dL and his urinary dipstick shows +2 ketonuria. What is the most important derangement to be seen on the arterial blood gases (ABG) of this patient?"
      />
    </div>
  );
}


function TopicMCQS(props) {
  
    const [isActive, setActive] = useState(false);

    //props
    const [current_url_page_param, setcurrent_url_page_param] = useState(false);
    const [json_index, setJSONindex] = useState(0);

    const [last_question, setLastQuestion] = useState(false);
    const [first_question, setFirstQuestion] = useState(false);

    const [json_data, setJSONdata] = useState(json_current);

    const [correctAnswer, setcorrectAnswer] = useState(false);

    const [tags, settags] = useState(false);

    const [relevancy, setrelevancy] = useState(false);

    const [currentQuestion, setcurrentQuestion] = useState(false);


    const [totalQuestions, settotalQuestions] = useState(false);

    const [options, setoptions] = useState(false);
    const [question, setquestion] = useState(false);

    const setjson = (data) => {

        setcorrectAnswer(data.correctAnswer);
        settags(data.tags);
        setrelevancy(data.relevancy);
        setcurrentQuestion(data.currentQuestion);
        settotalQuestions(data.totalQuestions);
        setoptions(data.options);
        setquestion(data.question);
      };

  const toggleClass = () => {
    setActive(!isActive);
  };

  useEffect(()=>{
    setMCQbasedonPageParam();
  }, [])

function setMCQbasedonPageParam(){
  var params = new URLSearchParams(props.location.search);
    if(params.has("page") === false)
    {
    } 
    else{
      var requiredQuestion = params.get("page");
    
        setJSONindex(requiredQuestion-1);
      
      
    }

}
  function nextMCQ (){
    var params = new URLSearchParams(props.location.search);
    if(params.has("page") === false)
    {
      if(json_index === 0)
      {
        setJSONindex(json_index+1);
      }
      else if(json_index=== (json_data.length - 1))//last question require
      {
       // FixJSONOptioninjson_data(json_index+1)
      //  setJSONindex(json_index+1);
       
      }
      else{
        setJSONindex(json_index+1);
        //console.log("json_index: "+json_index);
      }
    } 
    else{
      var requiredQuestion = params.get("page");
      if(requiredQuestion >> json_data.length || requiredQuestion << 0 )//some one is shit // 
      {
        setJSONindex(json_index+1);
      }
      else{
        setJSONindex(requiredQuestion-1);//changed by useEFFECT
        //setcurrent_url_page_param(requiredQuestion-1)
      }
      
    }
  }
  function previousMCQ(){
    var params = new URLSearchParams(props.location.search);
    if(!params.has("page"))
    {
      if(json_index === 0) //already first mcq
      {
      
      }
      else{
       
        setJSONindex(json_index-1);
      }
    } 
    else{
      var requiredQuestion = params.get("page");
      if(requiredQuestion >> json_data.length || requiredQuestion << 0 )//some one is shit // 
      {
      
        setJSONindex(json_index-1);
      }
      else{
       
        setJSONindex(requiredQuestion-1);//changed by useEFFECT
        //setcurrent_url_page_param(requiredQuestion-1)
      }
      
    }
  }
  function FixJSONOptioninjson_data(index){//DEPRECATED // already fixed using python
    //eg Options:"Increased Na+ |\nIncreased serum pH |\nDecreased pCO2 |\nDecreased HCO3- |\nDecreased K+ |"
    //after conversion: [Increased Na+ , Increased serum pH , Decreased pCO2 , Decreased HCO3- , Decreased K+] // 5 length
    if (typeof json_data[index].Options === 'string'){
      const optionarray = json_data[index].Options.split("|");
      optionarray.splice(-1);
     
      let trimedArr = optionarray.map(str => str.trim());
      json_data[index].Options = optionarray;
    }
    if (typeof json_data[index].Options === 'string'){
     console.log("string")
    }
    if (Array.isArray(json_data[index].Options)){
      console.log("array")
      const ar = json_data[json_index].Options;
      ar.map((data)=>{console.log(data)})
     }
    

   // return optionarray;

  }


  function getURLparam(urlparamname = "page") {
    var params = new URLSearchParams(props.location.search);
    if (params.has(urlparamname)) {
      return params.get(urlparamname);
    }
    else { return false }
  }
  //egpath => /smart-sets/anymodule/anytopic
  //props.params => contain => props.params.module and props.params.topic
  //props.params.module = anymodule  (how => /smart-sets/* => /smart-sets/anymodule)
  //props.params.topic = anytopic (how  => /smart-sets/*/* => /smart-sets/anymodule/anytopic)
  //props.location.search = for => /smart-sets/module?param
    return (
        <Layout>
            <Seo title="Smart Sets" />
            <div>
                <h1>{JSON.stringify(props.params)}</h1>
                <h2>{JSON.stringify(json_data[0])}</h2>
                <h2>index: {json_index}</h2>
                <h2>question:   {json_data[json_index].Question}</h2>
                <h2>   options={json_data[json_index].Options}</h2>
             
              
<button onClick={nextMCQ}>nextMCQ</button>
<button onClick={previousMCQ}>previousMCQ</button>
                <Link to={props.location.pathname +"?page="+json_index+2}>change url</Link>
                <button navigate={props.location.pathname +"?page="+json_index+2} >change url</button>
                
                <Foo
                    correctAnswer={json_data[json_index].Answer}
                    tags={json_data[json_index].Tags}
                    relevancy={json_data[json_index].Relevancy}
                    currentQuestion={json_index+1}
                    totalQuestions={json_data.length}
                    options={json_data[json_index].Options}
                   
                    question={json_data[json_index].Question}
                    previousMCQ={previousMCQ}
                    nextMCQ={nextMCQ}
                    
                    />
            </div>
        </Layout>
    )
}

export default TopicMCQS
